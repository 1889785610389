/* eslint-disable */
import React, { useRef, useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import EmailEditor from "react-email-editor";
import { useDropzone } from "react-dropzone";
import cookie from "react-cookies";
import Select from "react-select";
import { apiUrl, clientheaderconfig } from "../../Helpers/Config";
import { showLoader, hideLoader, showAlert, CompanyID } from "../../Helpers/SettingHelper";
import axios from "axios";
import styled from "styled-components";
var qs = require("qs");
import "./style.css";
const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
`;

const Bar = styled.div`
  flex: 1;
  background-color: #5a5fe0;
  color: #fff;
  padding: 10px;
  display: flex;
  max-height: 70px;
  h1 {
    flex: 1;
    font-size: 16px;
    text-align: left;
    color: #fff;
    margin-top: 12px;
  }

  button {
    flex: 1;
    padding: 10px;
    margin-left: 10px;
    font-size: 14px;
    font-weight: bold;
    background-color: #000;
    color: #fff;
    border: 0px;
    max-width: 150px;
    cursor: pointer;
  }
`;

const FileInput = styled.input`
  margin: 10px 0;
  padding: 10px;
  border: 2px solid #4caf50;
  border-radius: 5px;
`;

const UploadButton = styled.button`
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 10px;
  transition: background-color 0.3s;

  &:hover {
    background-color: #45a049;
  }
`;

const ProgressContainer = styled.div`
  width: 100%;
  margin-top: 20px;
`;

const ProgressBar = styled.div`
  height: 20px;
  background-color: #4caf50;
  border-radius: 5px;
  transition: width 0.3s;
`;

const Message = styled.p`
  margin-top: 1px;
`;
const StyledWrapper = styled.div`
  background-color: #fff;
  div {
    height: ${parseInt(window.innerHeight) - 65}px !important;
  }
`;
const EmailBuilder = (props) => {
  const emailEditorRef = useRef(null);

 

  console.log(emailEditorRef, props.match?.params, 'emailEditorRefemailEditorRef')
  useEffect(() => {
    loadOldDesign();
    loadGallery();
    if (props.match?.params?.TemplateID) {
      getTemplateDetails();
    }
  }, []);

  const [templateTitle, setTemplateTitle] = useState("");
  const [templateTitleError, setTemplateTitleError] = useState(false);
  const [templateStatus, setTemplateStatus] = useState("");
  const [templateStatusError, setTemplateStatusError] = useState("");

  const setTitle = (e) => {
    setTemplateTitle(e.target.value);
    setTemplateTitleError(false);
  };
  const setStatus = (value) => {
    setTemplateStatus(value);
    setTemplateStatusError(false);
  };

  const getTemplateDetails = () => {
    var urlShringTxt =
      apiUrl +
      "clientpanel/emailBuilder/details?editID=" +
      props.match?.params?.TemplateID+'&company_id='+CompanyID();

    axios.get(urlShringTxt, clientheaderconfig).then((res) => {
      if (res.data.status === "ok") {
        var result = res.data.result;
        if (result.templateDesig !== "" && result.templateDesig !== null) {
          emailEditorRef.current.editor.loadDesign(
            JSON.parse(result.templateDesign)
          );
          setTemplateTitle(result.templateTitle);
          var templateStatus =
            result.templateStatus == "A" ? "Active" : "In Active";
          var status =
            result.templateStatus !== "" && result.templateStatus !== null
              ? {
                  label: templateStatus,
                  value: result.templateStatus,
                }
              : "";
          setTemplateStatus(status);
        }
      }
    });
  };

  const exportHtml = () => {
    var error = 0;
    if (templateTitle === "") {
      setTemplateTitleError(true);
      error++;
    } else {
      setTemplateTitleError(false);
    }
    if (templateStatus === "") {
      setTemplateStatusError(true);
      error++;
    } else {
      setTemplateStatusError(false);
    }

    if (error === 0) {
      emailEditorRef.current.editor.exportHtml((data) => {
        const { design, html } = data;
        var postObject = {
          loginID: cookie.load("clientID"),
          company_id:CompanyID(),
          templateTitle: templateTitle,
          templateDesign: JSON.stringify(design),
          templateHtml: html,
          status: templateStatus?.value || "A",
          action: "add",
        };
        showLoader("savedesign");
        var url = "add";
        if (props.match?.params?.TemplateID) {
          postObject["action"] = "edit";
          postObject["edit_id"] = props.match?.params?.TemplateID;
          url = "edit";
        }
        axios
          .post(
            `${apiUrl}clientpanel/emailBuilder/${url}`,
            qs.stringify(postObject),
            clientheaderconfig
          )
          .then((res) => {
            hideLoader("savedesign");
            var resulet = res.data;
            var errMsg =
              resulet.form_error !== "" ? resulet.form_error : resulet.message;
            if (resulet.status === "success") {
              showAlert("Success", errMsg, "success", "No");
              props.history.push("/clientpanel/emailBuilder");
            } else {
              showAlert("Error", errMsg, "error", "No");
            }
          });
      });
    }
  };
  const [preview, setPreview] = useState(false);
  const togglePreview = () => {
    const unlayer = emailEditorRef.current?.editor;

    if (preview) {
      unlayer?.hidePreview();
      setPreview(false);
    } else {
      unlayer?.showPreview("desktop");
      setPreview(true);
    }
  };

  const onDesignLoad = (data) => {
    console.log("onDesignLoad", data);
    console.log(emailEditorRef.current.editor.registerCallback, 'emailEditorRef.current.editor.registerCallback')
    emailEditorRef.current.editor.registerCallback(
      "selectImage",
      function (data, done) {
        alert('gfdsgfgdgdg')
        $("#mediaLibrary").modal("show");
        $("#mediaLibrary .gallery-item img").bind("click", function (e) {
          done({ url: $(e.target).attr("src") });
          $("#mediaLibrary").modal("hide");
        });
      }
    );
  };

  const loadOldDesign = () => {    
    
    if (emailEditorRef.current.editor !== null) {

      
      emailEditorRef.current.editor.addEventListener(
        "design:loaded",
        onDesignLoad
      );
     
      emailEditorRef.current.editor.registerCallback(
        "selectImage",
        function (data, done) {
          alert('gfdsgfgdgdg')
          $("#mediaLibrary").modal("show");
          $("#mediaLibrary .gallery-item img").bind("click", function (e) {
            done({ url: $(e.target).attr("src") });
            $("#mediaLibrary").modal("hide");
          });
        }
      );
    } else {
      setTimeout(() => {
        loadOldDesign();
      }, 1000);
    }
  };

  const [galleryList, setgalleryList] = useState([]);
  const [imageURL, setimageURL] = useState("");
  const loadGallery = async () => {
    const responses = await axios.get(
      "https://walletapi.goepicpay.com/api/settings/getgallery"
    );
    if (responses?.data?.status === "ok") {
      setgalleryList(responses?.data?.result || []);
      setimageURL(responses?.data?.imageURL);
    }
  };
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [message, setMessage] = useState({ text: "", success: false });
  const onUpload = async () => {
    if (!file) {
      setMessage({ text: "No file selected", success: false });
      return;
    }
    const formData = new FormData();
    formData.append("imageFile", file);
    try {
      setMessage({ text: "", success: false });
      const response = await axios.post(
        "https://walletapi.goepicpay.com/api/settings/uploadGallery",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            console.log(
              progressEvent.loaded,
              Math.round((progressEvent.loaded * 100) / progressEvent.total),
              "progressEvent.loaded"
            );
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setUploadPercentage(percentCompleted);
          },
        }
      );
      setMessage({ text: "File uploaded successfully", success: true });
      loadGallery();
      setTimeout(() => {
        setMessage({ text: "", success: false });
        setUploadPercentage(0);
      }, 3000);
    } catch (error) {
      console.log(error, "errorerror");
      setMessage({ text: "Error uploading file", success: false });
      setTimeout(() => {
        setUploadPercentage(0);
        setMessage({ text: "", success: false });
      }, 3000);
    }
  };
  const [file, setFiles] = useState(null);
  const [dragdropMag, setDragdropMag] = useState(
    "Drag & drop some files here, or click to select files"
  );
  const onDrop = useCallback((acceptedFiles) => {
    setFiles(acceptedFiles[0]);
    setDragdropMag(acceptedFiles[0].name);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  const closeModal = () => {
    $("#mediaLibrary").modal("hide");
  };
  return (
    <Container>
      <Bar>
        <h1>EpicPay Email Composer</h1>
        <button onClick={togglePreview}>
          {preview ? "Hide" : "Show"} Preview
        </button>
        <button onClick={exportHtml} id="savedesign">
          Save Design
        </button>
        <Link to={"/clientpanel/emailBuilder"}>
          <button onClick={exportHtml} id="savedesign">
            Go Back
          </button>
        </Link>
      </Bar>
      <div className="card-body row g-3 mt-4" style={{ width: "95%" }}>
        <div className="col-md-6">
          <div className="form-floating form-floating-outline mb-4">
            <input
              type="text"
              value={templateTitle}
              className={
                templateTitleError === true
                  ? "form-control is-invalid"
                  : "form-control"
              }
              name="templateTitle"
              onChange={setTitle}
              id="templateTitle"
            />
            <label htmlFor="templateTitle">
              Title <span className="error">*</span>
            </label>
            {templateTitleError === true && (
              <span className="error">This field required</span>
            )}
          </div>
        </div>
        <div
          className={
            templateStatusError === true
              ? "col-md-6 error-select error"
              : "col-md-6"
          }
        >
          <div className="form-floating form-floating-outline custm-select-box">
            <Select
              value={templateStatus}
              onChange={setStatus}
              placeholder="Select Status"
              options={[
                { value: "A", label: "Active" },
                { value: "I", label: "In Active" },
              ]}
              isClearable={true}
            />
            <label className="select-box-label">
              Status<span className="error">*</span>
            </label>
            {templateStatusError === true && (
              <span className="error">This field required</span>
            )}
          </div>
        </div>
      </div>
      <StyledWrapper>
        <EmailEditor ref={emailEditorRef} />
      </StyledWrapper>
      <div id="mediaLibrary" className="modal" role="dialog">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">My Media Library</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={closeModal}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div
                {...getRootProps()}
                className={`dropzone ${isDragActive ? "active" : ""}`}
              >
                <input {...getInputProps()} />
                <p>{dragdropMag}</p>
              </div>

              <UploadButton onClick={onUpload}>Upload</UploadButton>
              <ProgressContainer>
                <ProgressBar style={{ width: `${uploadPercentage}%` }} />
              </ProgressContainer>
              {console.log(message, "messagemessage")}
              {message.text !== "" && (
                <Message
                  className={`message ${message.success ? "success" : "error"}`}
                >
                  {message.text}
                </Message>
              )}

              <div className="gallery">
                {galleryList.length > 0 &&
                  galleryList.map((item, index) => {
                    return (
                      <div className="gallery-item" key={index}>
                        <img src={`${imageURL}${item.galleryImage}`} />
                        <div className="gallery-caption">
                          {item.galleryName}
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default EmailBuilder;
