/* eslint-disable */
import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";
import update from "immutability-helper";
import { validated } from "react-custom-validation";
import { GET_FORMPOST, GET_DETAILDATA } from "../../../actions";
import { apiUrl, clientheaderconfig } from "../../Helpers/Config";
import {
  showLoader,
  hideLoader,
  showAlert,
  userID,
  clientID,
  CompanyID,
  isEmpty,
  isSingleSelect,
  isCustomerSelect,
} from "../../Helpers/SettingHelper";
import PageLoader from "../../Helpers/PageLoader";
import Header from "../Layout/Header";
import Topmenu from "../Layout/Topmenu";
import Footer from "../Layout/Footer";
var module = "clientpanel/emailCampaign/";
var moduleName = "Email Campaign";
var modulePath = "/clientpanel/emailCampaign";
class Form extends Component {
  constructor(props) {
    super(props);
    var editID = "";
    if (this.props.match.path === "/clientpanel/emailcampaign/edit/:EditID") {
      editID = this.props.match.params.EditID;
    }
    this.state = {
      editID: editID,
      pageloading: false,
      postdata: {
        emailcampaignTitle: "",
        emailTemplate: "",
        emailcampaignSchedule:"",
        selectedCustomer: [],
        status: "",        
        action: "add",
      },
      loading: true,
      formpost: [],
      companyDetail: [],
      customerList: [],
    };
  }
  componentDidMount() {
    this.loadCustomers();
    this.loadEmailTemplate();
    if (this.state.editID !== "") {
      var params = {
        params: "company_id=" + CompanyID() + "&editID=" + this.state.editID,
        url: apiUrl + module + "details",
        type: "client",
      };
      this.setState({ pageloading: true });
      this.props.getDetailData(params);
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.state.formpost !== nextProps.formpost &&
      this.props.formpost != nextProps.formpost
    ) {
      if (nextProps.formpost.length > 0) {
        hideLoader("submit_frm", "class");
        var errMsg =
          nextProps.formpost[0].form_error !== ""
            ? nextProps.formpost[0].form_error
            : nextProps.formpost[0].message;
        if (nextProps.formpost[0].status === "success") {
          showAlert("Success", errMsg, "success", "No");
          this.props.history.push(modulePath);
        } else {
          showAlert("Error", errMsg, "error", "No");
        }
      }
    }
    if (
      this.state.companyDetail !== nextProps.detaildata &&
      this.state.editID !== ""
    ) {
      this.setState({ companyDetail: nextProps.detaildata }, function () {
        if (nextProps.detaildata[0].status === "ok") {
          var result = nextProps.detaildata[0].result;

          var emailcampaignStatus =
            result.emailcampaignStatus == "A" ? "Active" : "In Active";
          var status =
            result.emailcampaignStatus !== "" && result.emailcampaignStatus !== null
              ? {
                  label: emailcampaignStatus,
                  value: result.emailcampaignStatus,
                }
              : "";
          var clientupdatedata = {
            emailcampaignTitle: result.emailcampaignTitle,
            selectedCustomer: result.selectedCustomer,
            emailTemplate: result.emailcampaignTemplateID,
            emailcampaignSchedule:(result.emailcampaignSchedule!==null && result.emailcampaignSchedule!=="")?new Date(result.emailcampaignSchedule):'',
            status: status,
            action: "edit",
          };
          this.setState({ postdata: clientupdatedata, pageloading: false });
        } else {
          this.props.history.push(modulePath);
          showAlert("Error", "Invalid" + moduleName, "error");
        }
      });
    }
  }

  sateValChange = (field, value) => {};

  /* signin - start*/
  fieldChange = (field, value) => {
    this.setState(
      update(this.state, { postdata: { [field]: { $set: value } } })
    );
  };

  loadCustomers() {
    var urlShringTxt =
      apiUrl + "clientpanel/customer/dropdownlist?company_id=" + CompanyID();

    axios.get(urlShringTxt, clientheaderconfig).then((res) => {
      if (res.data.status === "ok") {
        this.setState({ customerList: res.data.result });
      }
    });
  }
  loadEmailTemplate() {
    var urlShringTxt =
      apiUrl +
      "clientpanel/emailBuilder/dropdownlist?company_id=" +
      CompanyID();
    axios.get(urlShringTxt, clientheaderconfig).then((res) => {
      if (res.data.status === "ok") {
        this.setState({ emailTemplateList: res.data.result });
      }
    });
  }

  handleSubmit = () => {
    showLoader("submit_frm", "class");
    var postData = this.state.postdata;
    var selectedCustomer = [];
    if(postData.selectedCustomer.length>0) {
      postData.selectedCustomer.map((item)=> {
        selectedCustomer.push(item.value);
        return "";
      })
    }
    var selectedCustomers = (selectedCustomer.length>0)?selectedCustomer.join(','):'';

    var postObject = {
      emailcampaignTitle: postData.emailcampaignTitle,
      emailcampaignTemplateID: postData.emailTemplate?.value,
      selectedCustomer: selectedCustomers,
      emailcampaignSchedule:postData.emailcampaignSchedule !== "" ? format(postData.emailcampaignSchedule, "yyyy-MM-dd H:mm:00"): "",
      status:
        Object.keys(postData.status).length > 0 ? postData.status.value : "A",
      loginID: clientID(),
      company_admin_id: clientID(),
      company_id: CompanyID(),
      action: postData.action,
    };

    var post_url = module + "add";
    if (postData.action === "edit" && this.state.editID !== "") {
      postObject["edit_id"] = this.state.editID;
      post_url = module + "edit";
    }
    this.props.getFormPost(postObject, post_url, "client");
  };

  render() {
    return (
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          <Header {...this.props} currentPage={"emailcampaign"} />
          <div className="layout-page">
            <Topmenu />
            <div className="content-wrapper">
              <div className="container-xxl flex-grow-1 container-p-y">
                <div className="row mb-3">
                  <div className="col-lg-10 col-md-6">
                    <h4 className="fw-bold">
                      {this.state.editID !== "" ? "Update" : "Add New"}{" "}
                      {moduleName}
                    </h4>
                  </div>
                  <div className="col-lg-2 col-md-6 text-end">
                    <Link to={modulePath}>
                      <button
                        type="button"
                        className="btn btn-outline-primary waves-effect"
                      >
                        Back
                      </button>
                    </Link>
                  </div>
                </div>

                <PostForm
                  {...this.props}
                  fields={this.state.postdata}
                  onChange={this.fieldChange}
                  onValid={this.handleSubmit}
                  customerList={this.state.customerList}
                  emailTemplateList={this.state.emailTemplateList}
                  error_msg={this.state.error_msg}
                  onInvalid={() => {
                    console.log("Form invalid!");
                    setTimeout(function () {
                      if ($("#modulefrm .is-invalid").length > 0) {
                        $("html, body").animate(
                          {
                            scrollTop:
                              $(document)
                                .find("#modulefrm .is-invalid:first")
                                .offset().top - 100,
                          },
                          500
                        );
                      }
                    }, 500);
                  }}
                />
              </div>
              <Footer />
            </div>
          </div>
        </div>

        <div className="layout-overlay layout-menu-toggle"></div>
        <div className="drag-target"></div>
        <PageLoader pageloading={this.state.pageloading} />
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  return {
    formpost: state.formpost,
    detaildata: state.detaildata,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getFormPost: (formPayload, postUrl, authType) => {
      dispatch({ type: GET_FORMPOST, formPayload, postUrl, authType });
    },
    getDetailData: (datas) => {
      dispatch({ type: GET_DETAILDATA, datas });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(Form);



function validationConfig(props) {
  const { emailcampaignTitle, emailTemplate, /* selectedCustomer, */ status } = props.fields;
  return {
    fields: ["emailcampaignTitle", "emailTemplate"/* , "selectedCustomer" */, "status"],
    validations: {
      emailcampaignTitle: [[isEmpty, emailcampaignTitle]],
      emailTemplate: [[isSingleSelect, emailTemplate]],
      /* selectedCustomer: [[isCustomerSelect, selectedCustomer]], */
      status: [[isSingleSelect, status]],      
    },
  };
}

class PostForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      source: "",
      destination: "",
    };
  }

  handleChange(name, value) {
    this.props.onChange(name, value);
  }
  changeSearch(name, e) {
    this.setState({ [name]: e.target.value });
  }

  sectCustomer(cust, type) {
    var updCust = this.props.fields.selectedCustomer;
    if (type === "add") {
      updCust.push(cust);
    } else if (type === "remove") {
      const index = updCust.indexOf(cust);
      updCust.splice(index, 1);
    }
    this.props.onChange("selectedCustomer", updCust);
  }
  checkingCust(currentCust, custLabel) {
    let selectedCustomer = this.props.fields.selectedCustomer;
    let updCustomer = [];
    selectedCustomer.map((item) => {
      updCustomer.push(item.value);
    });
    if (updCustomer.indexOf(currentCust) < 0) {
      var source = this.state.source;
      if (source !== "") {
        if (custLabel.toLowerCase().indexOf(source.toLowerCase()) >= 0) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    } else {
      return false;
    }
  }
  checkingSelectCust(custLabel) {
    var destination = this.state.destination;
    if (destination !== "") {
      if (custLabel.toLowerCase().indexOf(destination.toLowerCase()) >= 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  selectAll(type) {
    let customerList = type === "add" ? this.props.customerList : [];
    this.props.onChange("selectedCustomer", customerList);
  }

  render() {
    const { fields, onChange, onValid, onInvalid, $field, $validation } =
      this.props;
    let errMsgemailcampaignTitle,
      errMsgemailTemplate,
      errMsgselectedCustomer,
      errMsgStatus = "";
    if ($validation.emailcampaignTitle.error.reason !== undefined) {
      errMsgemailcampaignTitle = $validation.emailcampaignTitle.show && (
        <span className="error">{$validation.emailcampaignTitle.error.reason}</span>
      );
    }
    if ($validation.emailTemplate.error.reason !== undefined) {
      errMsgemailTemplate = $validation.emailTemplate.show && (
        <span className="error">{$validation.emailTemplate.error.reason}</span>
      );
    }
    /* if ($validation.selectedCustomer.error.reason !== undefined) {
      errMsgselectedCustomer = $validation.selectedCustomer.show && (
        <span className="error">
          {$validation.selectedCustomer.error.reason}
        </span>
      );
    } */

    if ($validation.status.error.reason !== undefined) {
      errMsgStatus = $validation.status.show && (
        <span className="error">{$validation.status.error.reason}</span>
      );
    }

    return (
      <form className="card fv-plugins-bootstrap5" id="modulefrm">
        <div className="card-body row g-3">
          <div className="col-md-6">
            <div className="form-floating form-floating-outline mb-4">
              <input
                type="text"
                className={
                  errMsgemailcampaignTitle !== "" &&
                  errMsgemailcampaignTitle !== false &&
                  errMsgemailcampaignTitle !== undefined
                    ? "form-control is-invalid"
                    : "form-control"
                }
                name="emailcampaignTitle"
                value={fields.emailcampaignTitle}
                {...$field("emailcampaignTitle", (e) => onChange("emailcampaignTitle", e.target.value))}
              />
              <label htmlFor="emailcampaignTitle">
                Title <span className="error">*</span>
              </label>
              {errMsgemailcampaignTitle}
            </div>
          </div>
          <div
            className={
              errMsgemailTemplate !== "" &&
              errMsgemailTemplate !== false &&
              errMsgemailTemplate !== undefined
                ? "col-md-6 error-select error"
                : "col-md-6"
            }
          >
            <div className="form-floating form-floating-outline custm-select-box">
              <Select
                value={fields.emailTemplate}
                onChange={this.handleChange.bind(this, "emailTemplate")}
                placeholder="Select Template"
                options={this.props.emailTemplateList}
                isClearable={true}
              />
              <label className="select-box-label">
                Template<span className="error">*</span>
              </label>
              {errMsgemailTemplate}
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-floating form-floating-outline mb-4 custm-date-box">
              <DatePicker
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                showTimeSelect
                dropdownMode="select"
                className="form-control"
                selected={fields.emailcampaignSchedule}
                timeIntervals={5}
                minDate={new Date()}
                dateFormat="dd/MM/yyyy h:mm aa"
                onChange={this.handleChange.bind(
                  this,
                  "emailcampaignSchedule"
                )}
              />
              <label className="select-box-label">
                Schedule Date & Time
              </label>
            </div>
          </div>
          <div
            className={
              errMsgStatus !== "" &&
              errMsgStatus !== false &&
              errMsgStatus !== undefined
                ? "col-md-6 error-select error"
                : "col-md-6"
            }
          >
            <div className="form-floating form-floating-outline custm-select-box">
              <Select
                value={fields.status}
                onChange={this.handleChange.bind(this, "status")}
                placeholder="Select Status"
                options={[
                  { value: "A", label: "Active" },
                  { value: "I", label: "In Active" },
                ]}
                isClearable={true}
              />
              <label className="select-box-label">
                Status<span className="error">*</span>
              </label>
              {errMsgStatus}
            </div>
          </div>
          <div className="col-md-12 dual-listbox-main">
            <div class="dual-listbox">
              <div class="list-container">
                <input
                  type="text"
                  class="search"
                  placeholder="Search..."
                  value={this.state.source}
                  onChange={this.changeSearch.bind(this, "source")}
                />
                {this.props.customerList.length > 0 && (
                  <ul class="list" id="list1">
                    {this.props.customerList.map((item, index) => {
                      const checkingCust = this.checkingCust(
                        item.value,
                        item.label
                      );
                      if (checkingCust === true) {
                        return (
                          <li
                            key={index}
                            onClick={this.sectCustomer.bind(this, item, "add")}
                          >
                            {item.label}
                          </li>
                        );
                      } else {
                        return "";
                      }
                    })}
                  </ul>
                )}
              </div>
              <div class="buttons">
                <button
                  type="button"
                  onClick={this.selectAll.bind(this, "add")}
                  title="Select All"
                >
                  →
                </button>
                <button
                  type="button"
                  onClick={this.selectAll.bind(this, "remove")}
                  title="Remove All"
                >
                  ←
                </button>
              </div>
              <div class="list-container">
                <input
                  type="text"
                  class="search"
                  value={this.state.destination}
                  placeholder="Search..."
                  onChange={this.changeSearch.bind(this, "destination")}
                />
                <ul class="list" id="list2">
                  {fields.selectedCustomer.length > 0 &&
                    fields.selectedCustomer.map((item, index) => {
                      const checkingSelectCust = this.checkingSelectCust(
                        item.label
                      );
                      if (checkingSelectCust === true) {
                        return (
                          <li
                            key={index}
                            onClick={this.sectCustomer.bind(
                              this,
                              index,
                              "remove"
                            )}
                          >
                            {item.label}
                          </li>
                        );
                      }
                    })}
                </ul>
                {errMsgselectedCustomer}
              </div>
            </div>
          </div>
          <div className="pt-1 pb-4 pt-1 text-end">
            <button
              type="button"
              className="btn btn-primary me-sm-3 me-1 waves-effect waves-light submit_frm"
              onClick={(e) => {
                e.preventDefault();
                this.props.$submit(onValid, onInvalid);
              }}
            >
              Submit
            </button>
            <Link to={modulePath}>
              <button
                type="reset"
                className="btn btn-label-secondary waves-effect"
              >
                Cancel
              </button>
            </Link>
          </div>
        </div>
      </form>
    );
  }
}
PostForm = validated(validationConfig)(PostForm);
