import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import $ from "jquery";
import Select from "react-select";
import update from "immutability-helper";
import { validated } from "react-custom-validation";
import { GET_FORMPOST, GET_DETAILDATA } from "../../../actions";
import { apiUrl, clientheaderconfig } from "../../Helpers/Config";
import {
  showLoader,
  hideLoader,
  showAlert,
  userID,
  clientID,
  CompanyID,
  uploadFile,
  removeFile,
} from "../../Helpers/SettingHelper";
import PageLoader from "../../Helpers/PageLoader";
import Header from "../Layout/Header";
import Topmenu from "../Layout/Topmenu";
import Footer from "../Layout/Footer";
import Editor from "../Layout/Editor";
var module = "clientpanel/clients/";
var moduleName = "Settings";
var modulePath = "/clientpanel/outlet";

class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageloading: false,
      clientdata: {
        company_name: "",
        company_site_url: "",
        company_menu_url:"",
        company_max_order_handle: "",
        company_logo: "",
        company_owner_name: "",
        company_postal_code: "",
        company_unit_no: "",
        company_floor_no: "",
        company_address: "",
        company_contact_number: "",
        company_email: "",
        company_tax_type: "",
        company_tax_percentage: "",
        company_gst_no: "",
        company_invoice_logo: "",
        enable_promotion_code_popup: "",
        enable_normal_popup: "",
        first_time_order_promotion: "",
        new_signup_promotion: "",
        social_media: [{ linktype: "", link: "" }],
        assign_availability: [],
        action: "add",
        company_stripe_mode: "",
        company_stripe_secret_test: "",
        company_stripe_secret_live: "",
        company_stripe_public_test: "",
        company_stripe_public_live: "",
        subscription: {
          monthly: { amount: "", infotext: "" },
          quarterly: { amount: "", infotext: "" },
          biannually: { amount: "", infotext: "" },
          annually: { amount: "", infotext: "" },
        },
        enable_subscription: false,
        socialanalytics: [""],
        typeofCreditRedeem: "",
        typeofCredit: "",
        description: "",
      },
      btndisabled: false,
      loading: true,
      tatList: [],
      promoList: [],
      formpost: [],
      companyDetail: [],
    };
  }
  componentDidMount() {
    this.loadPromotions();

    var params = {
      params: "company_id=" + CompanyID(),
      url: apiUrl + module + "details",
      type: "client",
    };
    this.setState({ pageloading: true });
    this.props.getDetailData(params);
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.state.formpost !== nextProps.formpost &&
      this.props.formpost !== nextProps.formpost
    ) {
      if (nextProps.formpost.length > 0) {
        hideLoader("submit_frm", "class");
        var errMsg =
          nextProps.formpost[0].form_error !== ""
            ? nextProps.formpost[0].form_error
            : nextProps.formpost[0].message;
        if (nextProps.formpost[0].status === "success") {
          showAlert("Success", errMsg, "success", "No");
        } else {
          showAlert("Error", errMsg, "error", "No");
        }
      }
    }
    if (this.state.companyDetail !== nextProps.detaildata) {
      this.setState({ companyDetail: nextProps.detaildata }, function () {
        if (nextProps.detaildata[0].status === "ok") {
          var result = nextProps.detaildata[0].result;

          var company_tax_type = result?.company_tax_type
            ? {
                label: result.company_tax_type,
                value: result.company_tax_type,
              }
            : "";

          var company_stripe_mode = result?.company_stripe_mode
            ? {
                label: result.company_stripe_mode === 1 ? "Live" : "Test",
                value: result.company_stripe_mode,
              }
            : "";

          var first_time_order_promotion = result?.first_time_order_promotion
            ? {
                label: result.first_time_order_promotion,
                value: result.first_time_order_promotion,
              }
            : "";
          var new_signup_promotion = result?.new_signup_promotion
            ? {
                label: result.new_signup_promotion,
                value: result.new_signup_promotion,
              }
            : "";
          var typeofCreditRedeem = "";
          if (result?.typeofCreditRedeem) {
            var Redeemlabel = "";
            if (result?.typeofCreditRedeem === "1") {
              Redeemlabel = "Bonus Model";
            } else if (result?.typeofCreditRedeem === "2") {
              Redeemlabel = "Cashback Model";
            }
            typeofCreditRedeem = {
              label: Redeemlabel,
              value: result?.typeofCreditRedeem,
            };
          }
          var typeofCredit = "";
          if (result?.typeofCredit) {
            var Creditlabel = "";
            if (result?.typeofCredit === "1") {
              Creditlabel = "Redeem Via Outlet";
            } else if (result?.typeofCredit === "2") {
              Creditlabel = "Redeem Via  Customer";
            }
            typeofCredit = {
              label: Creditlabel,
              value: result?.typeofCredit,
            };
          }

          var clientupdatedata = {
            company_name: result.company_name,
            company_site_url: result?.company_site_url || '',
            company_menu_url:result.company_menu_url,
            company_max_order_handle: result?.company_max_order_handle || "",
            company_logo: result.company_logo,
            company_owner_name: result?.company_owner_name || "",
            company_postal_code: result?.company_postal_code || "",
            company_unit_no: result?.company_unit_number || "",
            company_floor_no: result?.company_floor_number || "",
            company_address: result?.company_address || "",
            company_contact_number: result?.company_contact_number || "",
            company_email: result?.company_email_address || "",
            company_tax_type: company_tax_type,
            company_tax_percentage: result?.company_tax_percentage || "",
            company_stripe_mode: company_stripe_mode,
            company_stripe_public_live:
              result?.company_stripe_public_live || "",
            company_stripe_public_test:
              result?.company_stripe_public_test || "",
            company_stripe_secret_live:
              result?.company_stripe_secret_live || "",
            company_stripe_secret_test:
              result?.company_stripe_secret_test || "",

            company_gst_no: result?.company_gst_no || "",
            company_invoice_logo: result?.company_invoice_logo || "",
            enable_promotion_code_popup:
              result?.enable_promotion_code_popup || "",
            enable_normal_popup: result?.enable_normal_popup || "",
            first_time_order_promotion: first_time_order_promotion,
            new_signup_promotion: new_signup_promotion,
            social_media: result?.social_media
              ? JSON.parse(result.social_media)
              : [{ linktype: "", link: "" }],
            assign_availability: [],
            subscription: result?.product_subscription
              ? JSON.parse(result.store_subscription)
              : {
                  monthly: { amount: "", infotext: "" },
                  quarterly: { amount: "", infotext: "" },
                  biannually: { amount: "", infotext: "" },
                  annually: { amount: "", infotext: "" },
                },
            enable_subscription: result?.enable_subscription,
            socialanalytics: result?.socialanalytics || [""],
            typeofCreditRedeem: typeofCreditRedeem,
            typeofCredit: typeofCredit,
            CreditBonusPercentage: result?.CreditBonusPercentage,
            description: result?.description || "",
            action: "edit",
          };
          this.setState({ clientdata: clientupdatedata, pageloading: false });
        } else {
          this.setState({ pageloading: false });
          this.props.history.push(modulePath);
          showAlert("Error", "Invalid Product", "error");
        }
      });
    }
  }

  loadPromotions() {
    var urlShringTxt =
      apiUrl + "clientpanel/promotion/dropdownlist?company_id=" + CompanyID();
    axios.get(urlShringTxt, clientheaderconfig).then((res) => {
      var promoList = [];
      if (res.data.status === "ok") {
        if (res.data.result.length > 0) {
          res.data.result.map((item) => {
            promoList.push({ label: item.label, value: item.label });
            return "";
          });
        }
      }
      this.setState({ promoList: promoList });
    });
  }

  sateValChange = (field, value) => {
    this.setState({ btndisabled: value });
  };

  /* signin - start*/
  fieldChange = (field, value) => {
    this.setState(
      update(this.state, { clientdata: { [field]: { $set: value } } })
    );
  };

  handleSubmit = () => {
    showLoader("submit_frm", "class");
    var postData = this.state.clientdata;

    var assign_availability = [];
    if (postData.assign_availability.length > 0) {
      postData.assign_availability.map((item) => {
        assign_availability.push(item.value);
        return "";
      });
    }
    
    var postObject = {
      company_name: postData.company_name,
      company_site_url: postData.company_site_url,
      company_menu_url:postData.company_menu_url,
      company_max_order_handle: postData.company_max_order_handle,
      company_logo: postData.company_logo,
      company_owner_name: postData.company_owner_name,
      company_postal_code: postData.company_postal_code,
      company_unit_no: postData.company_unit_no,
      company_floor_no: postData.company_floor_no,
      company_address: postData.company_address,
      company_contact_number: postData.company_contact_number,
      company_email: postData.company_email,
      company_tax_type:
        Object.keys(postData.company_tax_type).length > 0
          ? postData.company_tax_type.value
          : "",
      company_tax_percentage:
        Object.keys(postData.company_tax_type).length > 0
          ? postData.company_tax_percentage
          : "",
      company_stripe_mode:
        Object.keys(postData.company_stripe_mode).length > 0
          ? postData.company_stripe_mode.value
          : "",
      company_stripe_secret_test: postData.company_stripe_secret_test,
      company_stripe_secret_live: postData.company_stripe_secret_live,
      company_stripe_public_test: postData.company_stripe_public_test,
      company_stripe_public_live: postData.company_stripe_public_live,

      company_gst_no: postData.company_gst_no,
      company_invoice_logo: postData.company_invoice_logo,
      enable_promotion_code_popup: postData.enable_promotion_code_popup,
      enable_normal_popup: postData.enable_normal_popup,
      first_time_order_promotion:
        Object.keys(postData.first_time_order_promotion).length > 0
          ? postData.first_time_order_promotion.value
          : "",
      new_signup_promotion:
        Object.keys(postData.new_signup_promotion).length > 0
          ? postData.new_signup_promotion.value
          : "",
      social_media:
        postData.social_media.length > 0
          ? JSON.stringify(postData.social_media)
          : "",
      assign_availability: assign_availability,
      subscription: JSON.stringify(postData.subscription),
      socialanalytics:
        postData.socialanalytics.length > 0
          ? postData.socialanalytics.join(",")
          : "",
      typeofCreditRedeem: postData.typeofCreditRedeem?.value || "",
      typeofCredit: postData.typeofCredit?.value || "",
      CreditBonusPercentage: postData.CreditBonusPercentage,
      description: postData.description,
      loginID: userID(),
      company_admin_id: clientID(),
      company_id: CompanyID(),
      action: postData.action,
    };
    var post_url = module + "update";

    this.props.getFormPost(postObject, post_url, "client");
  };

  render() {
    return (
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          <Header {...this.props} currentPage="settings" />
          <div className="layout-page">
            <Topmenu />
            <div className="content-wrapper">
              <div className="container-xxl flex-grow-1 container-p-y">
                <div className="row mb-3">
                  <div className="col-lg-10 col-md-6">
                    <h4 className="fw-bold">{moduleName}</h4>
                  </div>
                </div>
                <PostForm
                  {...this.props}
                  fields={this.state.clientdata}
                  btndisabled={this.state.btndisabled}
                  onChange={this.fieldChange}
                  sateValChange={this.sateValChange}
                  onValid={this.handleSubmit}
                  error_msg={this.state.error_msg}
                  promoList={this.state.promoList}
                  onInvalid={() => {
                    console.log("Form invalid!");
                    setTimeout(function () {
                      if ($("#modulefrm .is-invalid").length > 0) {
                        $("html, body").animate(
                          {
                            scrollTop:
                              $(document)
                                .find("#modulefrm .is-invalid:first")
                                .offset().top - 100,
                          },
                          500
                        );
                      }
                    }, 500);
                  }}
                />
              </div>
              <Footer />
            </div>
          </div>
        </div>

        <div className="layout-overlay layout-menu-toggle"></div>
        <div className="drag-target"></div>
        <PageLoader pageloading={this.state.pageloading} />
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  return {
    formpost: state.formpost,
    detaildata: state.detaildata,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getFormPost: (formPayload, postUrl, authType) => {
      dispatch({ type: GET_FORMPOST, formPayload, postUrl, authType });
    },
    getDetailData: (datas) => {
      dispatch({ type: GET_DETAILDATA, datas });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(Form);

const isEmpty = (value) => (value === "" ? "This field is required." : null);
const isValidOption = (taxType, value) => {
  if (Object.keys(taxType).length > 0) {
    return value === "" ? "This field is required." : null;
  }
};
function validationConfig(props) {
  const {
    company_name,
    company_site_url,
    company_owner_name,
    company_postal_code,
    company_unit_no,
    company_address,
    company_email,
    company_tax_type,
    company_tax_percentage,
    /*  company_stripe_secret_test,
    company_stripe_secret_live,
    company_stripe_public_test,
    company_stripe_public_live,
    company_stripe_mode, */
  } = props.fields;

  return {
    fields: [
      "company_name",
      "company_site_url",
      "company_owner_name",
      "company_postal_code",
      "company_unit_no",
      "company_address",
      "company_email",
      "company_tax_type",
      "company_tax_percentage",
      /* "company_stripe_secret_test",
      "company_stripe_secret_live",
      "company_stripe_public_test",
      "company_stripe_public_live", */
    ],

    validations: {
      company_name: [[isEmpty, company_name]],
      company_site_url: [[isEmpty, company_site_url]],
      company_owner_name: [[isEmpty, company_owner_name]],
      company_postal_code: [
        [isEmpty, company_postal_code],
        /* [isNumber, company_postal_code], */
      ],
      company_unit_no: [[isEmpty, company_unit_no]],
      company_address: [[isEmpty, company_address]],
      company_email: [[isEmpty, company_email]],
      company_tax_percentage: [
        [isValidOption, company_tax_type, company_tax_percentage],
      ],
      /*  company_stripe_secret_test: [[isEmpty, company_stripe_secret_test]],
      company_stripe_secret_live: [[isEmpty, company_stripe_secret_live]],
      company_stripe_public_test: [[isEmpty, company_stripe_public_test]],
      company_stripe_public_live: [[isEmpty, company_stripe_public_live]], */
    },
  };
}

class PostForm extends Component {
  constructor(props) {
    super(props);
    this.setContent = this.setContent.bind(this);
  }

  handleChange(name, value) {
    this.props.onChange(name, value);
  }
  handleSelectChange(name, value) {
    this.props.onChange(name, value);
  }
  handleChangeCheck(name, event) {
    var value = event.target.checked === true ? "Yes" : "No";
    if (name === "assign_availability") {
      var assign_availability = this.props.fields.assign_availability;
      if (
        this.props.fields.assign_availability.indexOf(event.target.value) >= 0
      ) {
        const index = assign_availability.indexOf(value);
        assign_availability.splice(index, 1);
      } else {
        assign_availability.push(event.target.value);
      }
      value = assign_availability;
    }

    this.props.onChange(name, value);
  }

  setContent(value) {
    this.props.onChange("description", value);
  }

  async uplaodFiles(imageType) {
    this.props.sateValChange("btndisabled", true);
    showLoader("ImageDetails");
    showLoader("ImageContainer");
    var imagefile = document.querySelector("#" + imageType);
    const file = imagefile.files[0];
    var fileDetail = await uploadFile(file, "company", "client");
    if (fileDetail.status === "ok") {
      this.props.onChange(imageType, fileDetail.url);
    }
    hideLoader("ImageContainer");
    hideLoader("ImageDetails");
    $("#" + imageType).val("");
    this.props.sateValChange("btndisabled", false);
  }
  async removeImage(fileNamme, imageType, e) {
    e.preventDefault();
    showLoader("ImageDetails", "class");
    var fileDetail = await removeFile(fileNamme, "client");
    if (fileDetail.status === "ok") {
      hideLoader("ImageDetails", "class");
      this.props.onChange(imageType, "");
    }
  }

  handleChangeSubscription(types, name, event) {
    var subscription = this.props.fields.subscription;
    var nameDetails = types.toLowerCase();
    if (name === "amount") {
      var validNumber = new RegExp(/^\d*\.?\d*$/);
      if (!validNumber.test(event.target.value)) {
        return false;
      }
    }
    subscription[nameDetails][name] = event.target.value;
    this.props.onChange(subscription, subscription);
  }

  handleChangeSocial(addIndex, e) {
    var updsocialanalytics = [];
    if (this.props.fields.socialanalytics.length > 0) {
      this.props.fields.socialanalytics.map((item, index) => {
        if (index === addIndex) {
          updsocialanalytics.push(e.target.value);
        } else {
          updsocialanalytics.push(item);
        }
        return "";
      });
    }
    this.props.onChange("socialanalytics", updsocialanalytics);
  }

  addsocialshare() {
    var socialanalytics = this.props.fields.socialanalytics;
    socialanalytics.push("");
    this.props.onChange("socialanalytics", socialanalytics);
  }
  removesocialshare(removeIndex) {
    var updsocialanalytics = [];
    if (this.props.fields.socialanalytics.length > 0) {
      this.props.fields.socialanalytics.map((item, index) => {
        if (index !== removeIndex) {
          updsocialanalytics.push(item);
        }
        return "";
      });
    }

    this.props.onChange("socialanalytics", updsocialanalytics);
  }

  render() {
    const { fields, onChange, onValid, onInvalid, $field, $validation } =
      this.props;
    let errMsgCompanyName,
      errMsgUrl,
      errMsgOwner,
      errMsgPostalCode,
      errMsgUnitNo,
      errMsgAddress,
      errMsgEmail,
      errMsgTax,
      errMsgStripe = "";
    if ($validation.company_name.error.reason !== undefined) {
      errMsgCompanyName = $validation.company_name.show && (
        <span className="error">{$validation.company_name.error.reason}</span>
      );
    }
    if ($validation.company_site_url.error.reason !== undefined) {
      errMsgUrl = $validation.company_site_url.show && (
        <span className="error">
          {$validation.company_site_url.error.reason}
        </span>
      );
    }
    if ($validation.company_owner_name.error.reason !== undefined) {
      errMsgOwner = $validation.company_owner_name.show && (
        <span className="error">
          {$validation.company_owner_name.error.reason}
        </span>
      );
    }
    if ($validation.company_postal_code.error.reason !== undefined) {
      errMsgPostalCode = $validation.company_postal_code.show && (
        <span className="error">
          {$validation.company_postal_code.error.reason}
        </span>
      );
    }
    if ($validation.company_unit_no.error.reason !== undefined) {
      errMsgUnitNo = $validation.company_unit_no.show && (
        <span className="error">
          {$validation.company_unit_no.error.reason}
        </span>
      );
    }

    if ($validation.company_address.error.reason !== undefined) {
      errMsgAddress = $validation.company_address.show && (
        <span className="error">
          {$validation.company_address.error.reason}
        </span>
      );
    }
    if ($validation.company_email.error.reason !== undefined) {
      errMsgEmail = $validation.company_email.show && (
        <span className="error">{$validation.company_email.error.reason}</span>
      );
    }
    if ($validation.company_tax_percentage.error.reason !== undefined) {
      errMsgTax = $validation.company_tax_percentage.show && (
        <span className="error">
          {$validation.company_tax_percentage.error.reason}
        </span>
      );
    }
    /* if ($validation.company_stripe_public_live.error.reason !== undefined) {
      errMsgStripe = $validation.company_stripe_public_live.show && (
        <span className="error">
          {$validation.company_stripe_public_live.error.reason}
        </span>
      );
    }
    if ($validation.company_stripe_public_test.error.reason !== undefined) {
      errMsgStripe = $validation.company_stripe_public_test.show && (
        <span className="error">
          {$validation.company_stripe_public_test.error.reason}
        </span>
      );
    }
    if ($validation.company_stripe_secret_live.error.reason !== undefined) {
      errMsgStripe = $validation.company_stripe_secret_live.show && (
        <span className="error">
          {$validation.company_stripe_secret_live.error.reason}
        </span>
      );
    }
    if ($validation.company_stripe_secret_test.error.reason !== undefined) {
      errMsgStripe = $validation.company_stripe_secret_test.show && (
        <span className="error">
          {$validation.company_stripe_secret_test.error.reason}
        </span>
      );
    } */
    var subscriptionTypes = ["Monthly", "Quarterly", "Biannually", "Annually"];

    return (
      <form className="card fv-plugins-bootstrap5" id="modulefrm">
        <div className="row g-3">
          <div
            className="accordion mt-3 accordion-header-primary"
            id="accordionStyle1"
          >
            <div className="accordion-item active">
              <h2 className="accordion-header">
                <button
                  type="button"
                  className="accordion-button"
                  data-bs-toggle="collapse"
                  data-bs-target="#accordionStyle1-3"
                  aria-expanded="true"
                >
                  General Info
                </button>
              </h2>
              <div
                id="accordionStyle1-3"
                className="accordion-collapse collapse show  mt-3"
                data-bs-parent="#accordionStyle1"
              >
                <div className="accordion-body">
                  <div className="row g-3">
                    <div className="col-md-6">
                      <div className="form-floating form-floating-outline mb-4">
                        <input
                          type="text"
                          className={
                            errMsgCompanyName !== "" &&
                            errMsgCompanyName !== false &&
                            errMsgCompanyName !== undefined
                              ? "form-control is-invalid"
                              : "form-control"
                          }
                          name="company_name"
                          value={fields.company_name}
                          {...$field("company_name", (e) =>
                            onChange("company_name", e.target.value)
                          )}
                        />
                        <label htmlFor="company_name">
                          Company Name<span className="error">*</span>
                        </label>
                        {errMsgCompanyName}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating form-floating-outline mb-4">
                        <input
                          type="text"
                          className={
                            errMsgUrl !== "" &&
                            errMsgUrl !== false &&
                            errMsgUrl !== undefined
                              ? "form-control is-invalid"
                              : "form-control"
                          }
                          name="company_site_url"
                          value={fields.company_site_url}
                          {...$field("company_site_url", (e) =>
                            onChange("company_site_url", e.target.value)
                          )}
                        />
                        <label htmlFor="company_site_url">
                          Site URL<span className="error">*</span>
                        </label>
                        {errMsgUrl}
                      </div>
                    </div>
                    <div className="col-md-6" style={{ display: "none" }}>
                      <div className="form-floating form-floating-outline mb-4">
                        <input
                          type="text"
                          className="form-control"
                          name="company_max_order_handle"
                          value={fields.company_max_order_handle}
                          {...$field("company_max_order_handle", (e) =>
                            onChange("company_max_order_handle", e.target.value)
                          )}
                        />
                        <label htmlFor="company_max_order_handle">
                          Maximum Handle Order Count
                        </label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating form-floating-outline custm-select-box mb-4 ">
                        <Select
                          value={fields.typeofCreditRedeem}
                          onChange={this.handleSelectChange.bind(
                            this,
                            "typeofCreditRedeem"
                          )}
                          placeholder="Select "
                          options={[
                            { label: "Bonus Model", value: "1" },
                            { label: "Cashback Model", value: "2" },
                          ]}
                        />
                        <label className="select-box-label">
                          Bonus Credit Type
                        </label>
                      </div>
                    </div>
                    {fields.typeofCreditRedeem?.value === "2" && (
                      <div className="col-md-3">
                        <div className="form-floating form-floating-outline mb-4">
                          <input
                            type="text"
                            className={
                              errMsgUrl !== "" &&
                              errMsgUrl !== false &&
                              errMsgUrl !== undefined
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                            name="CreditBonusPercentage"
                            value={fields.CreditBonusPercentage}
                            {...$field("CreditBonusPercentage", (e) =>
                              onChange("CreditBonusPercentage", e.target.value)
                            )}
                          />
                          <label htmlFor="company_site_url">
                            Credit Percentage
                          </label>
                        </div>
                      </div>
                    )}
                    <div className="col-md-3">
                      <div className="form-floating form-floating-outline custm-select-box mb-4 ">
                        <Select
                          value={fields.typeofCredit}
                          onChange={this.handleSelectChange.bind(
                            this,
                            "typeofCredit"
                          )}
                          placeholder="Select "
                          options={[
                            { label: "Redeem Via Outlet ", value: "1" },
                            { label: "Redeem Via  Customer", value: "2" },
                          ]}
                        />
                        <label className="select-box-label">Redeem Type</label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating form-floating-outline mb-4">
                        <input
                          type="text"
                          className="form-control"
                          name="company_menu_url"
                          value={fields.company_menu_url}
                          {...$field("company_menu_url", (e) =>
                            onChange("company_menu_url", e.target.value)
                          )}
                        />
                        <label htmlFor="company_menu_url">
                          Menu URL
                        </label>
                        {errMsgUrl}
                      </div>
                    </div>

                    <div className="col-md-12">
                      <label>Company Description</label>
                      <Editor
                        setContent={this.setContent.bind(this)}
                        data={
                          fields.description !== null ? fields.description : ""
                        }
                      />
                    </div>

                    <div className="col-md-6">
                      <div className="form-floating form-floating-outline mb-4">
                        <div className="mb-3" id="ImageContainer">
                          <label htmlFor="formFile" className="form-label">
                            Company Logo
                          </label>
                          <input
                            className="form-control"
                            type="file"
                            id="company_logo"
                            onChange={(event) => {
                              this.uplaodFiles("company_logo", event);
                              return false;
                            }}
                          />
                          For Recommendation Size : <b>200px X 200px</b><br/>
                          For Crop Image : <b><a href="https://imageresizer.com/crop-image" target="_blank">click here</a></b>
                        </div>
                        {fields.company_logo !== "" && (
                          <div className="dz-preview dz-processing dz-image-preview dz-success dz-complete">
                            <div className="dz-details" id="ImageDetails">
                              <div className="dz-thumbnail">
                                <img alt="" src={fields.company_logo} />
                              </div>
                            </div>
                            <a
                              className="dz-remove"
                              href="/"
                              onClick={this.removeImage.bind(
                                this,
                                fields.company_logo,
                                "company_logo"
                              )}
                            >
                              Remove file
                            </a>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion-item ">
              <h2 className="accordion-header">
                <button
                  type="button"
                  className="accordion-button collapsed"
                  data-bs-toggle="collapse"
                  data-bs-target="#accordionprice"
                  aria-expanded="false"
                >
                  Contact Info
                </button>
              </h2>

              <div
                id="accordionprice"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionStyle1"
              >
                <div className="accordion-body">
                  <div className="row g-3">
                    <div className="col-md-6">
                      <div className="form-floating form-floating-outline mb-4">
                        <input
                          type="text"
                          className={
                            errMsgOwner !== "" &&
                            errMsgOwner !== false &&
                            errMsgOwner !== undefined
                              ? "form-control is-invalid"
                              : "form-control"
                          }
                          name="company_owner_name"
                          value={fields.company_owner_name}
                          {...$field("company_owner_name", (e) =>
                            onChange("company_owner_name", e.target.value)
                          )}
                        />
                        <label htmlFor="company_owner_name">
                          Owner Name <span className="error">*</span>
                        </label>
                        {errMsgOwner}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating form-floating-outline mb-4">
                        <input
                          type="text"
                          className={
                            errMsgPostalCode !== "" &&
                            errMsgPostalCode !== false &&
                            errMsgPostalCode !== undefined
                              ? "form-control is-invalid"
                              : "form-control"
                          }
                          name="company_postal_code"
                          value={fields.company_postal_code}
                          {...$field("company_postal_code", (e) =>
                            onChange("company_postal_code", e.target.value)
                          )}
                        />
                        <label htmlFor="company_postal_code">
                          Postal Code<span className="error">*</span>
                        </label>
                        {errMsgPostalCode}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating form-floating-outline mb-4">
                        <input
                          type="text"
                          className={
                            errMsgUnitNo !== "" &&
                            errMsgUnitNo !== false &&
                            errMsgUnitNo !== undefined
                              ? "form-control is-invalid"
                              : "form-control"
                          }
                          name="company_unit_no"
                          value={fields.company_unit_no}
                          {...$field("company_unit_no", (e) =>
                            onChange("company_unit_no", e.target.value)
                          )}
                        />
                        <label htmlFor="company_unit_no">
                          Unit No.<span className="error">*</span>
                        </label>
                        {errMsgUnitNo}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating form-floating-outline mb-4">
                        <input
                          type="text"
                          className="form-control"
                          name="company_floor_no"
                          value={fields.company_floor_no}
                          {...$field("company_floor_no", (e) =>
                            onChange("company_floor_no", e.target.value)
                          )}
                        />
                        <label htmlFor="company_floor_no">Floor No.</label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating form-floating-outline mb-4">
                        <input
                          type="text"
                          className={
                            errMsgAddress !== "" &&
                            errMsgAddress !== false &&
                            errMsgAddress !== undefined
                              ? "form-control is-invalid"
                              : "form-control"
                          }
                          name="company_address"
                          value={fields.company_address}
                          {...$field("company_address", (e) =>
                            onChange("company_address", e.target.value)
                          )}
                        />
                        <label htmlFor="company_address">
                          Address<span className="error">*</span>
                        </label>
                        {errMsgAddress}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating form-floating-outline mb-4">
                        <input
                          type="text"
                          className="form-control"
                          name="company_contact_number"
                          value={fields.company_contact_number}
                          {...$field("company_contact_number", (e) =>
                            onChange("company_contact_number", e.target.value)
                          )}
                        />
                        <label htmlFor="company_contact_number">
                          Contact Number
                        </label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating form-floating-outline mb-4">
                        <input
                          type="text"
                          className={
                            errMsgEmail !== "" &&
                            errMsgEmail !== false &&
                            errMsgEmail !== undefined
                              ? "form-control is-invalid"
                              : "form-control"
                          }
                          name="company_email"
                          value={fields.company_email}
                          {...$field("company_email", (e) =>
                            onChange("company_email", e.target.value)
                          )}
                        />
                        <label htmlFor="company_email">
                          Email Address<span className="error">*</span>
                        </label>
                        {errMsgEmail}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion-item ">
              <h2 className="accordion-header">
                <button
                  type="button"
                  className="accordion-button collapsed"
                  data-bs-toggle="collapse"
                  data-bs-target="#accordionvoucher"
                  aria-expanded="false"
                >
                  Tax Settings
                </button>
              </h2>

              <div
                id="accordionvoucher"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionStyle1"
              >
                <div className="accordion-body">
                  <div className="row g-3">
                    <div className="col-md-6">
                      <div className="form-floating form-floating-outline custm-select-box mb-4 ">
                        <Select
                          value={fields.company_tax_type}
                          onChange={this.handleSelectChange.bind(
                            this,
                            "company_tax_type"
                          )}
                          placeholder="Select Tax Type"
                          options={[
                            { label: "Exclusive", value: "Exclusive" },
                            { label: "Inclusive", value: "Inclusive" },
                          ]}
                        />
                        <label className="select-box-label">Tax Type</label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating form-floating-outline mb-4">
                        <input
                          type="text"
                          className={
                            errMsgTax !== "" &&
                            errMsgTax !== false &&
                            errMsgTax !== undefined
                              ? "form-control is-invalid"
                              : "form-control"
                          }
                          name="company_tax_percentage"
                          value={fields.company_tax_percentage}
                          {...$field("company_tax_percentage", (e) =>
                            onChange("company_tax_percentage", e.target.value)
                          )}
                          id="company_tax_percentage"
                        />
                        <label htmlFor="company_tax_percentage">
                          Tax Amount Percentage{" "}
                          {fields.company_tax_type !== "" && (
                            <span className="error">*</span>
                          )}
                        </label>
                        {errMsgTax}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion-item ">
              <h2 className="accordion-header">
                <button
                  type="button"
                  className="accordion-button collapsed"
                  data-bs-toggle="collapse"
                  data-bs-target="#accordionstripe"
                  aria-expanded="false"
                >
                  Stripe Settings
                </button>
              </h2>

              <div
                id="accordionstripe"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionStyle1"
              >
                <div className="accordion-body">
                  <div className="row g-3">
                    <div className="col-md-6">
                      <div className="form-floating form-floating-outline custm-select-box mb-4 ">
                        <Select
                          value={fields.company_stripe_mode}
                          onChange={this.handleSelectChange.bind(
                            this,
                            "company_stripe_mode"
                          )}
                          placeholder="Select Stripe Mode"
                          options={[
                            { label: "Live", value: "1" },
                            { label: "Test", value: "2" },
                          ]}
                        />
                        <label className="select-box-label">Mode</label>
                      </div>
                    </div>
                    <div className="col-md-6"></div>
                  </div>
                  <div className="row g-3">
                    <div className="col-md-6">
                      <div className="form-floating form-floating-outline mb-4">
                        <input
                          type="text"
                          className={
                            errMsgStripe !== "" &&
                            errMsgStripe !== false &&
                            errMsgStripe !== undefined
                              ? "form-control is-invalid"
                              : "form-control"
                          }
                          name="company_stripe_public_live"
                          value={fields.company_stripe_public_live}
                          {...$field("company_stripe_public_live", (e) =>
                            onChange(
                              "company_stripe_public_live",
                              e.target.value
                            )
                          )}
                          id="company_stripe_public_live"
                        />
                        <label htmlFor="company_stripe_public_live">
                          Live Public Key{" "}
                          {fields.company_stripe_public_live !== "" && (
                            <span className="error">*</span>
                          )}
                        </label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating form-floating-outline mb-4">
                        <input
                          type="text"
                          className={
                            errMsgStripe !== "" &&
                            errMsgStripe !== false &&
                            errMsgStripe !== undefined
                              ? "form-control is-invalid"
                              : "form-control"
                          }
                          name="company_stripe_secret_live"
                          value={fields.company_stripe_secret_live}
                          {...$field("company_stripe_secret_live", (e) =>
                            onChange(
                              "company_stripe_secret_live",
                              e.target.value
                            )
                          )}
                          id="company_stripe_secret_live"
                        />
                        <label htmlFor="company_stripe_secret_live">
                          Live Secret Key{" "}
                          {fields.company_stripe_secret_live !== "" && (
                            <span className="error">*</span>
                          )}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="row g-3">
                    <div className="col-md-6">
                      <div className="form-floating form-floating-outline mb-4">
                        <input
                          type="text"
                          className={
                            errMsgStripe !== "" &&
                            errMsgStripe !== false &&
                            errMsgStripe !== undefined
                              ? "form-control is-invalid"
                              : "form-control"
                          }
                          name="company_stripe_public_test"
                          value={fields.company_stripe_public_test}
                          {...$field("company_stripe_public_test", (e) =>
                            onChange(
                              "company_stripe_public_test",
                              e.target.value
                            )
                          )}
                          id="company_stripe_public_test"
                        />
                        <label htmlFor="company_stripe_public_test">
                          Test Public Key{" "}
                          {fields.company_stripe_public_test !== "" && (
                            <span className="error">*</span>
                          )}
                        </label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating form-floating-outline mb-4">
                        <input
                          type="text"
                          className={
                            errMsgStripe !== "" &&
                            errMsgStripe !== false &&
                            errMsgStripe !== undefined
                              ? "form-control is-invalid"
                              : "form-control"
                          }
                          name="company_stripe_secret_test"
                          value={fields.company_stripe_secret_test}
                          {...$field("company_stripe_secret_test", (e) =>
                            onChange(
                              "company_stripe_secret_test",
                              e.target.value
                            )
                          )}
                          id="company_stripe_secret_test"
                        />
                        <label htmlFor="company_stripe_secret_test">
                          Test Secret Key{" "}
                          {fields.company_stripe_secret_test !== "" && (
                            <span className="error">*</span>
                          )}
                        </label>
                        {errMsgStripe}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {fields.enable_subscription && (
              <div className="accordion-item ">
                <h2 className="accordion-header">
                  <button
                    type="button"
                    className="accordion-button collapsed"
                    data-bs-toggle="collapse"
                    data-bs-target="#accordionsubscription"
                    aria-expanded="false"
                  >
                    Store Subscription
                  </button>
                </h2>

                <div
                  id="accordionsubscription"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionStyle1"
                >
                  <div className="accordion-body">
                    <div className="row g-3">
                      {subscriptionTypes.map((item, index) => {
                        return (
                          <div className="row g-3" key={index}>
                            <div className="col-md-1">
                              <h5 className="mb-0">{item}</h5>
                            </div>
                            <div className="col-md-2">
                              <div className="form-floating form-floating-outline mb-4">
                                <input
                                  type="text"
                                  className="form-control"
                                  name="alias_name"
                                  value={
                                    fields.subscription[item.toLowerCase()]
                                      .amount
                                  }
                                  onChange={this.handleChangeSubscription.bind(
                                    this,
                                    item,
                                    "amount"
                                  )}
                                />
                                <label htmlFor="alias_name">Amount</label>
                              </div>
                            </div>
                            <div className="col-md-2">
                              <div className="form-floating form-floating-outline mb-4">
                                <input
                                  type="text"
                                  className="form-control"
                                  name="infotext"
                                  value={
                                    fields.subscription[item.toLowerCase()]
                                      .infotext
                                  }
                                  onChange={this.handleChangeSubscription.bind(
                                    this,
                                    item,
                                    "infotext"
                                  )}
                                />
                                <label htmlFor="alias_name">Save Text</label>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="accordion-item ">
              <h2 className="accordion-header">
                <button
                  type="button"
                  className="accordion-button collapsed"
                  data-bs-toggle="collapse"
                  data-bs-target="#accordionsocialanalytics"
                  aria-expanded="false"
                >
                  Generate Unique Links
                </button>
              </h2>

              <div
                id="accordionsocialanalytics"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionStyle1"
              >
                <div className="accordion-body">
                  <div className="row g-3">
                    {fields.socialanalytics.length > 0 ? (
                      fields.socialanalytics.map((item, index) => {
                        return (
                          <div className="row g-3" key={index}>
                            <div className="col-md-2">
                              <div className="form-floating form-floating-outline mb-4">
                                <input
                                  type="text"
                                  className="form-control"
                                  name="alias_name"
                                  value={item}
                                  onChange={this.handleChangeSocial.bind(
                                    this,
                                    index
                                  )}
                                />
                                <label htmlFor="alias_name">Name</label>
                              </div>
                            </div>
                            <div class="col-md-2">
                              <div className="mt-3">
                                {fields.socialanalytics.length > 1 && (
                                  <span
                                    class="act-minus-icon"
                                    onClick={this.removesocialshare.bind(
                                      this,
                                      index
                                    )}
                                  >
                                    <i class="mdi mdi-minus-circle-outline"></i>
                                  </span>
                                )}
                                {fields.socialanalytics.length - 1 ===
                                  index && (
                                  <span
                                    class="act-plus-icon"
                                    onClick={this.addsocialshare.bind(this)}
                                  >
                                    <i class="mdi mdi-plus-circle-outline"></i>
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div className="row g-3">
                        <div className="col-md-2">
                          <div className="form-floating form-floating-outline mb-4">
                            <input
                              type="text"
                              className="form-control"
                              /*  onChange={this.handleChangeSubscription.bind(
                                this,
                                item,
                                "amount"
                              )} */
                            />
                            <label htmlFor="alias_name">Name</label>
                          </div>
                        </div>
                        <div class="col-md-2">
                          <div className="mt-3">
                            <span
                              class="act-plus-icon"
                              onClick={this.addsocialshare.bind(this)}
                            >
                              <i class="mdi mdi-plus-circle-outline"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="pt-1 pb-4 pr-2 text-end"
            style={{ paddingRight: "20px" }}
          >
            <button
              type="button"
              disabled={this.props.btndisabled}
              className="btn btn-primary me-sm-3 me-1 waves-effect waves-light submit_frm"
              onClick={(e) => {
                e.preventDefault();
                if (!this.props.btndisabled) {
                  this.props.$submit(onValid, onInvalid);
                }
              }}
            >
              Submit
            </button>
            <Link to={modulePath}>
              <button
                type="reset"
                className="btn btn-label-secondary waves-effect"
              >
                Cancel
              </button>
            </Link>
          </div>
        </div>
      </form>
    );
  }
}
PostForm = validated(validationConfig)(PostForm);
